import { useInfiniteQuery } from '@tanstack/react-query'
import { type Sort } from 'mongodb'
import { aDrawnFeature, aOrganization, useAtomValue } from '~/atoms'
import { toast } from '~/components/ui'
import type { Listing, ModelFind } from '~/models'
import type { ApiResponse } from './utils'

export async function aggregateListings(
  aggregate: {}[]
): Promise<ApiResponse & { results: any }> {
  const response = await fetch('/api/listings/aggregate', {
    body: JSON.stringify({ aggregate }),
    method: 'POST',
  })
  const result = await response.json()
  if (!result.success) {
    console.error('Error aggregating listings', result.error)
    throw new Error(`There was a problem aggregating results: ${result.error}`)
  }
  return result
}

type FetchListingsResponse = ApiResponse & {
  listings: Listing[]
  count: number
  nextOffset: number
}

export async function fetchListings({
  find,
  sort,
  project,
  limit,
  offset,
  signal,
}: {
  find: ModelFind
  sort?: Sort
  project?: {}
  limit?: number
  offset?: number
  signal?: AbortSignal
}): Promise<FetchListingsResponse> {
  const response = await fetch('/api/listings', {
    body: JSON.stringify({
      find,
      sort,
      project,
      limit,
      offset,
    }),
    method: 'POST',
    signal,
  })
  const result = await response.json()
  if (!result.success) {
    console.error('Error loading properties', result.error)
    throw new Error(`There was a problem fetching results: ${result.error}`)
  }
  return result
}

export function useListingsQuery({
  key,
  sort,
  find,
  limit,
  project,
  // offset,
  enabled = true,
  refetchOnWindowFocus = false,
  refetchOnMount = false,
  refetchInterval,
}: {
  key: string[]
  find?: {}
  sort?: Sort
  limit?: number
  project?: {}
  // offset?: number
  enabled?: boolean
  refetchOnWindowFocus?: boolean
  refetchOnMount?: boolean
  refetchInterval?: number
}) {
  const organization = useAtomValue(aOrganization)
  const drawnFeature = useAtomValue(aDrawnFeature)

  const listingsQuery = useInfiniteQuery({
    retry(failureCount) {
      if (failureCount === 0) {
        toast({
          variant: 'destructive',
          title: 'Error Fetching Listings',
          description: drawnFeature
            ? 'Please simplify your polygon.'
            : 'There was a problem fetching listings',
        })
        if (drawnFeature) {
          return false
        }
      }

      return true
    },
    queryKey: key,
    queryFn: ({ signal, pageParam = 0 }) => {
      return fetchListings({
        find: {
          delete: { $ne: true },
          ...find,
          'organization._id': organization!._id,
        },
        sort,
        project,
        limit,
        offset: pageParam,
        signal,
      })
    },
    enabled,
    refetchOnWindowFocus,
    refetchOnMount,
    // keepPreviousData: true,
    refetchInterval,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => lastPage.nextOffset,
    // onError: (error) => {
    //   console.error('Error loading global listings', error)
    // },
  })

  return {
    isLoading:
      listingsQuery.isLoading ||
      listingsQuery.isFetching ||
      listingsQuery.isRefetching ||
      (listingsQuery.isPending && enabled),
    // ||listingsQuery.isFetching,
    isFetching: listingsQuery.isFetching,
    // || listingsQuery.isRefetching,
    isRefetching: listingsQuery.isRefetching,
    isError: listingsQuery.isError,
    listings: listingsQuery.data?.pages?.map((p) => p.listings).flat() || [],
    pages: listingsQuery.data?.pages || [],
    count: listingsQuery.data?.pages?.[0].count,
    refetch: listingsQuery.refetch,
    fetchNextPage: listingsQuery.fetchNextPage,
    hasNextPage: listingsQuery.hasNextPage,
    hasPreviousPage: listingsQuery.hasPreviousPage,
    isFetchingNextPage: listingsQuery.isFetchingNextPage,
  }
}
